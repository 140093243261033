import styled from "styled-components";

export interface InputProps {}

export const Input = styled.input`
  background: rgba(196, 196, 196, 0.15);
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  height: 47px;
  padding: 1rem;
  color: #fff;
`;
