import React from "react";
import styled from "styled-components";
import { Input } from "./Input";
import { Button } from "./Button";
import { Auth0Context } from "../Auth0Context";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "./ErrorMessage";
import { Link, useNavigate } from "react-router-dom";
import { GoogleSignInButton } from "./GoogleSignInButton";

type Inputs = {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
};

export const SignUp: React.FC = () => {
  const [error, setError] = React.useState<string | null | undefined>(null); // eslint-disable-line

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting, isDirty }, // eslint-disable-line
  } = useForm<Inputs>();

  const Auth0 = React.useContext(Auth0Context);
  const navigate = useNavigate();
  const password = React.useRef({});
  password.current = watch("password", "");

  function handleGoogleSignIn() {
    Auth0.webAuth.authorize({
      connection: "google-oauth2",
    });
  }

  function onSubmit(data: Inputs) {
    Auth0.webAuth.signup(
      {
        connection: "Username-Password-Authentication",
        email: data.email,
        password: data.password,
        userMetadata: {
          name: data.name,
        },
      },
      function (err) {
        if (err) {
          if (
            err.description &&
            err.description === "unauthorized_registration"
          ) {
            setError("You are not allowed to sign-up at this time.");
          } else {
            setError("An unexpected error has occurred. Please try again.");
          }

          console.log(err);
          return reset();
        }
        return navigate("/login");
      }
    );
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormTitle>Create An Account</FormTitle>

        {error && (
          <ErrorMessageContainer>
            <ErrorMessage message={error} />
          </ErrorMessageContainer>
        )}

        <FormElementsWrapper>
          <FormControl>
            <Label htmlFor="name">Your name</Label>
            <Input id="name" {...register("name", { required: true })} />
          </FormControl>

          <FormControl>
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              {...register("email", { required: true })}
            />
          </FormControl>

          <PasswordsContainer>
            <FormControl>
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                type="password"
                {...register("password", {
                  required: true,
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                })}
              />

              {errors.password ? (
                <p className="error">{errors.password.message}</p>
              ) : (
                <p className="info">*password must be 8 letters min.</p>
              )}
            </FormControl>

            <FormControl>
              <Label htmlFor="confirm-password">Confirm Password</Label>
              <Input
                id="confirm-password"
                type="password"
                {...register("confirmPassword", {
                  required: true,
                  validate: (value) =>
                    value === password.current || "The passwords do not match",
                })}
              />
              {errors.confirmPassword && (
                <p className="error">{errors.confirmPassword.message}</p>
              )}
            </FormControl>
          </PasswordsContainer>

          <StyledButton
            // @ts-ignore
            type="submit"
            label="Create my account"
            disabled={isSubmitting}
          />
        </FormElementsWrapper>

        <StyledText>Or</StyledText>

        {/*@ts-ignore*/}
        <StyledGoogleSignInButton onClick={handleGoogleSignIn} />
      </Form>

      <Footer>
        <p>Already Joined Ampersand?</p>
        <Link to="/login">Log into your account</Link>
      </Footer>
    </div>
  );
};

const Form = styled.form`
  margin: 32px 0 0 0;
  width: 100%;
  flex-shrink: 0;

  .error {
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.4px;
    color: #ffa5a5;
    margin: 5px 0 0 0;
  }

  .info {
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.4px;
    color: #c4c4c4;
    margin: 5px 0 0 0;
  }
`;

const FormTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 1.4px;
  color: #ececec;
  margin: 0 0 9px 0;
  text-transform: uppercase;
  flex-shrink: 0;
`;

const FormControl = styled.div`
  margin: 0 0 15px 0;
  flex-shrink: 0;
`;

const Label = styled.label`
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #c4c4c4;
  margin: 0 0 9px 0;
  flex-shrink: 0;
`;

const StyledButton = styled(Button)`
  margin: 25px 0 0 0;
  flex-shrink: 0;

  :disabled {
    cursor: not-allowed;
  }
`;

const Footer = styled.footer`
  margin: 65px 0 0 0;
  flex-shrink: 0;

  p {
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 23px;
    letter-spacing: 1.4px;
    color: #ececec;
    flex-shrink: 0;
  }

  a {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #c4c4c4;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;

    &:after {
      content: "";
      display: block;
      height: 1px;
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 125px;
      border-bottom: 1px solid #c4c4c4;
    }
  }
`;

const ErrorMessageContainer = styled.div`
  margin: 12px 0;
  max-width: 411px;
`;

const FormElementsWrapper = styled.div`
  max-width: 256px;
`;

const PasswordsContainer = styled.div`
  margin: 47px 0 0 0;
`;

const StyledGoogleSignInButton = styled(GoogleSignInButton)`
  max-width: 256px;
  flex-shrink: 0;
`;

const Text = styled.p`
  max-width: 468px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.4px;
  color: #ececec;
  margin: 16px 0 0 0;
`;

const StyledText = styled(Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #c4c4c4;
  margin: 25px 0;
  text-align: center;
  max-width: 256px;
  width: 100%;
  flex-shrink: 0;
`;
