// @ts-nocheck

import { atom } from "recoil";

export const TIME_SPENT_TO_COMPLETE_ALL_TEST_CASES =
  "TimeSpentToCompleteAllTestCases";
export const TimeSpentToCompleteAllTestCases = atom<number>({
  key: TIME_SPENT_TO_COMPLETE_ALL_TEST_CASES,
  default: 0, // seconds
});

export const FeedbackState = atom<{
  previousResponse: null | any;
}>({
  key: "FeedbackState",
  default: {
    previousResponse: null,
  },
});
