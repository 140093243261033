import React from "react";
import styled from "styled-components";
import { Input } from "./Input";
import { Button } from "./Button";
import { Auth0Context } from "../Auth0Context";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "./ErrorMessage";
import { useNavigate } from "react-router-dom";

type Inputs = {
  email: string;
};

export const PasswordReset: React.FC = () => {
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [error, setError] = React.useState<string | null | undefined>(null); // eslint-disable-line
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty }, // eslint-disable-line
  } = useForm<Inputs>();
  const Auth0 = React.useContext(Auth0Context);
  const navigate = useNavigate();

  function onSubmit(data: Inputs) {
    Auth0.webAuth.changePassword(
      {
        connection: "Username-Password-Authentication",
        email: data.email,
      },
      function (err, resp) {
        if (err) {
          console.error(err);
        } else {
          setSuccessMessage(resp);
        }
      }
    );
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {!successMessage && <FormTitle>Reset Password</FormTitle>}

        {!successMessage && error && !isDirty && (
          <ErrorMessageContainer>
            <ErrorMessage message="Sorry! The email or password entered isn’t right. We can help you recover it if needed." />
          </ErrorMessageContainer>
        )}

        {!successMessage && (
          <FormElementsWrapper>
            <FormControl>
              <Label>Email</Label>
              <Input {...register("email", { required: true })} />
            </FormControl>

            <StyledButton
              // @ts-ignore
              type="submit"
              label="Reset Password"
              disabled={isSubmitting}
            />
          </FormElementsWrapper>
        )}

        {successMessage && <StyledText>{successMessage}</StyledText>}

        {successMessage && (
          <StyledButton
            label="Back To Login"
            onClick={() => navigate("/login")}
          />
        )}
      </Form>
    </div>
  );
};

const Text = styled.p`
  max-width: 468px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.4px;
  color: #ececec;
  margin: 16px 0 0 0;
`;

const Form = styled.form`
  margin: 32px 0 0 0;
  width: 100%;
  flex-shrink: 0;
  max-width: 258px;
`;

const FormTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 1.4px;
  color: #ececec;
  margin: 0 0 9px 0;
  text-transform: uppercase;
  flex-shrink: 0;
`;

const FormControl = styled.div`
  margin: 0 0 15px 0;
  flex-shrink: 0;
`;

const Label = styled.label`
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #c4c4c4;
  margin: 0 0 9px 0;
  flex-shrink: 0;
`;

const StyledButton = styled(Button)`
  margin: 25px 0 0 0;
  flex-shrink: 0;

  :disabled {
    cursor: not-allowed;
  }
`;

const StyledText = styled(Text)`
  font-weight: 500;
`;

const ErrorMessageContainer = styled.div`
  margin: 12px 0;
  max-width: 411px;
`;

const FormElementsWrapper = styled.div`
  max-width: 256px;
`;
