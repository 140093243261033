import React from "react";
import styled from "styled-components";

export interface MenuItemProps extends React.HTMLAttributes<HTMLButtonElement> {
  icon?: any;
  label: string;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  label,
  icon,
  ...props
}) => {
  return (
    <StyledMenuItem {...props}>
      <span>{label}</span> {icon}
    </StyledMenuItem>
  );
};

const StyledMenuItem = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 41px;
  width: 100%;
  padding: 18px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.4px;
  transition: background-color 0.2s ease-in-out;

  color: #ffffff;

  &:nth-child(1) {
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  }
  &:nth-last-child(1) {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  &:hover {
    background: rgba(196, 196, 196, 0.16);
  }
`;
