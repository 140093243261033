import React from "react";
import styled from "styled-components";
import { ArrowDownIcon } from "./ArrowDownIcon";
import { Menu } from "./Menu";
import { MenuItem } from "./MenuItem";
import { AnimatePresence, motion } from "framer-motion";
import { useClickAway } from "react-use";
import { LogoutIcon } from "./LogoutIcon";
import { Auth0Context } from "../Auth0Context";

export interface ProfileMenuProps {
  user: { name?: string; email: string };
}

export const ProfileMenu: React.FC<ProfileMenuProps> = (props) => {
  const ref = React.useRef(null);
  const [visible, setVisibility] = React.useState(false);
  const Auth0 = React.useContext(Auth0Context);

  useClickAway(ref, () => {
    setVisibility(false);
  });

  function logout() {
    setVisibility(false);
    // logout
    Auth0.logout();
  }

  function toggleMenu() {
    if (!visible) {
      setVisibility(true);
    }
  }

  return (
    <StyledProfileMenu {...props}>
      <StyledButton onClick={toggleMenu}>
        {props.user && <span>{props.user.name || props.user.email}</span>}{" "}
        <ArrowDownIcon width={24} height={24} />
      </StyledButton>

      <AnimatePresence>
        {visible && (
          <StyledMenuWrapper ref={ref}>
            <Menu>
              <MenuItem
                label="Your profile"
                onClick={() => setVisibility(false)}
              />
              <MenuItem label="Logout" icon={<LogoutIcon />} onClick={logout} />
            </Menu>
          </StyledMenuWrapper>
        )}
      </AnimatePresence>
    </StyledProfileMenu>
  );
};

const StyledButton = styled.button`
  display: flex;
  padding: 0 20px 0 0;
  color: rgba(236, 236, 236, 1);
  align-items: center;
  cursor: pointer;
  span {
    display: block;
    margin: 0 14px 0 0;
  }
`;

const StyledProfileMenu = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const StyledMenuWrapper = styled(motion.div)`
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translateX(-55%);
  z-index: 10;
`;
