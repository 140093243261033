import React from "react";
import styled from "styled-components";
import { ComposedLogo } from "../components/Logo";
import { Login } from "../components/Login";
import { useMatch } from "react-router-dom";
import { PasswordReset } from "../components/PasswordReset";
import { SignUp } from "../components/SignUp";

export const Auth: React.FC = () => {
  const matchLogin = useMatch("/login");
  const matchSignUp = useMatch("/sign-up");
  const matchChangePassword = useMatch("/change-password");

  return (
    <LoginContainer className="auth">
      <ColumnOne className="auth__column-one">
        <ComposedLogo />
      </ColumnOne>

      <ColumnTwo className="auth__column-two">
        <div>
          <Title>
            Your perspective is the most <span>precious</span> thing we have
          </Title>

          <Text>
            Thank you for starting this journey with us. We believe opening the
            dialogue between builders of AI tools and the experts using them is
            the only responsible way to go
          </Text>

          {matchLogin && <Login />}
          {matchSignUp && <SignUp />}
          {matchChangePassword && <PasswordReset />}
        </div>
      </ColumnTwo>
    </LoginContainer>
  );
};

const LoginContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
  overflow: hidden;
`;

const ColumnOne = styled.div`
  background: #363740;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  min-height: 0;
`;

const ColumnTwo = styled.div`
  flex: 1;
  padding: 96px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background: #484a51;
  overflow: auto;
  min-height: 0;
  height: auto;

  & > div {
    margin: auto 0;
  }
`;

const Title = styled.h1`
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 0.4px;
  color: #ffffff;
  max-width: 392px;
  flex-shrink: 0;

  span {
    position: relative;
    z-index: 1;

    &:after {
      content: "";
      display: block;
      height: 10px;
      width: 105%;
      background: #f8ac2f;
      position: absolute;
      bottom: 4px;
      left: 0;
      z-index: -1;
    }
  }
`;

const Text = styled.p`
  max-width: 468px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.4px;
  color: #ececec;
  margin: 16px 0 0 0;
`;
