import React from "react";
import styled from "styled-components";
import { ComposedLogo } from "../components/Logo";
import { Auth0Context, removeAuthState } from "../Auth0Context";
import { useNavigate } from "react-router-dom";
import { FormKey, TestCasesKey, useFetchApplicationsForUser } from "../hooks";
import {
  ERROR_CODES,
  USER_APPLICATION_ID_KEY,
  USER_ID_KEY,
} from "../constants";
import axios from "axios";
import { getAccessToken, isAuthenticated } from "../utils";
import { AccessDenied } from "./AccessDenied";
import {
  authorizeUser,
  fetchFormForApplication,
  fetchTestCasesForApplication,
} from "../api";
import { useQueryClient } from "react-query";

export const Callback = () => {
  const Auth0 = React.useContext(Auth0Context);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [userId, setUserId] = React.useState<string>("");
  const [errorTitle, setErrorTitle] = React.useState<string>("");
  const [errorDescription, setErrorDescription] = React.useState<string>("");
  const { data: applications } = useFetchApplicationsForUser(userId);
  const userApplicationId =
    applications && applications.length > 0 ? applications[0].id : null;
  const applicationId =
    applications && applications.length > 0
      ? applications[0].application.id
      : "";
  const [showLogo, setShowLogo] = React.useState(false);

  async function parseHash() {
    try {
      if (!isAuthenticated()) {
        await Auth0.parseHash();
        setShowLogo(true); // helps prevent logo flickering
        navigate(window.location.pathname, { replace: true });

        const accessToken = getAccessToken();

        // set axios headers
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;

        const user = await authorizeUser();

        localStorage.setItem(USER_ID_KEY, user.id.toString());

        setUserId(user.id.toString());

        // localStorage.setItem(USER_ID_KEY, "1");
        // setUserId("1");
      }
    } catch (err) {
      removeAuthState();

      if (err.errorDescription) {
        setErrorTitle(err.errorDescription);

        if (err.error === "unauthorized") {
          setErrorDescription(ERROR_CODES.UNAUTHORIZED.DESCRIPTION);
        }
      } else {
        navigate(window.location.pathname, { replace: true });
        setErrorTitle(ERROR_CODES.UNEXPECTED_ERROR.TITLE);
        setErrorDescription(ERROR_CODES.UNEXPECTED_ERROR.DESCRIPTION);
      }

      // Error 😨
      if (err.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      } else if (err.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(err.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", err.message);

        console.log(err);
      }
    }
  }

  React.useEffect(() => {
    parseHash();
  }, []); // eslint-disable-line

  React.useEffect(() => {
    if (applications && userApplicationId) {
      localStorage.setItem(
        USER_APPLICATION_ID_KEY,
        userApplicationId.toString()
      );
    }

    if (applications && applicationId) {
      queryClient.prefetchQuery([TestCasesKey, applicationId], () =>
        fetchTestCasesForApplication(applicationId).catch(console.error)
      );
      queryClient.prefetchQuery([FormKey, applicationId], () =>
        fetchFormForApplication(applicationId).catch(console.error)
      );
    }

    if (userId && applications) {
      navigate("/overview", { replace: true });
    }
  }, [applications, applicationId, userId]); // eslint-disable-line

  return (
    <CallbackContainer className="callback">
      {errorTitle.length === 0 && showLogo && (
        <LogoContainer>
          <ComposedLogo />
        </LogoContainer>
      )}

      {errorTitle.length > 0 && (
        <AccessDenied
          errorTitle={errorTitle}
          errorDescription={errorDescription}
          handleBackNavigation={
            errorTitle === ERROR_CODES.UNEXPECTED_ERROR.TITLE
              ? () => Auth0.logout()
              : undefined
          }
        />
      )}
    </CallbackContainer>
  );
};

const CallbackContainer = styled.div`
  text-align: center;
  height: 100%;
`;

const LogoContainer = styled.div`
  background-color: rgba(54, 55, 64, 1);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`;
