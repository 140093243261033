import React from "react";
import { Navigate, Route, useLocation } from "react-router-dom";
import { isAuthenticated } from "../utils";

export const ProtectedRoute = (props: {
  path: string;
  element: any;
  children?: React.ReactNode;
}) => {
  const signedIn = isAuthenticated();
  const location = useLocation();

  if (!signedIn) {
    return <Navigate to="/login" />;
  }

  if (location.pathname === "/") {
    return <Navigate to="/overview" replace />;
  }

  return (
    <Route path={props.path} element={React.cloneElement(props.element)}>
      {props.children}
    </Route>
  );
};
