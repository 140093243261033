import React from "react";
import styled from "styled-components";
import { FlagIcon } from "./FlagIcon";
import { useForm } from "react-hook-form";
import {
  useCreateFlaggedResponse,
  useFetchApplicationsForUser,
  useFetchFormForApplication,
  useFetchNextTestCaseForApplicationForUser,
} from "../hooks";
import { getUserId } from "../utils";
import { useRecoilValue } from "recoil";
import { FeedbackState } from "../state";
import { TimerContext } from "../TimerContext";

export interface FlagToPeerProps {
  callback: () => void;
}

export const FlagToPeer: React.FC<FlagToPeerProps> = (props) => {
  const userId = getUserId();

  const { TimeMe } = React.useContext(TimerContext);

  const {
    data: userApplications,
    isLoading: isLoadingUserApplications,
  } = useFetchApplicationsForUser(getUserId());

  const applicationId = userApplications?.[0].application.id ?? "";

  const {
    data: nextTestCase,
    isLoading: isLoadingNextTestCase,
  } = useFetchNextTestCaseForApplicationForUser({
    userId,
    applicationId,
  });

  const { data: form, isLoading: isLoadingForm } = useFetchFormForApplication(
    applicationId
  );

  const feedbackState = useRecoilValue(FeedbackState);

  const createFlaggedResponse = useCreateFlaggedResponse(
    applicationId as number
  );

  const isFlagged =
    feedbackState.previousResponse && feedbackState.previousResponse.isFlagged;

  const flaggedReason = isFlagged
    ? feedbackState.previousResponse.flaggedReason
    : null;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }, // eslint-disable-line
  } = useForm();

  React.useEffect(() => {
    if (flaggedReason) {
      setValue("reason", flaggedReason);
    }
  }, [flaggedReason]); // eslint-disable-line

  const onSubmit = ({ reason }: any) => {
    const { previousResponse } = feedbackState;
    const testCaseId = previousResponse
      ? previousResponse.testCase.id
      : nextTestCase?.id;
    const id = previousResponse ? previousResponse.id : null;

    // stop the timer
    TimeMe.stopTimer(testCaseId);
    Math.round(TimeMe.getTimeOnPageInSeconds(testCaseId));
    createFlaggedResponse.mutate({
      flaggedReason: reason,
      formId: form?.id as number,
      timeSpent: Math.round(TimeMe.getTimeOnPageInSeconds(testCaseId)),
      userId: Number(userId),
      testCaseId,
      id,
    });

    if (props.callback) {
      props.callback();
    }
  };

  if (isLoadingForm || isLoadingUserApplications || isLoadingNextTestCase) {
    return null;
  }

  const submitButtonText = isFlagged ? "Next" : "Send flag & skip";

  return (
    <StyledFlagToPeer>
      {!isFlagged && (
        <StyledHeader isFlagged={isFlagged}>
          <p>Not Sure?</p>

          <span>
            <FlagIcon /> Flag to a peer
          </span>
        </StyledHeader>
      )}

      {isFlagged && (
        <StyledHeader isFlagged={isFlagged}>
          <span>
            <FlagIcon /> Study Flagged
          </span>
        </StyledHeader>
      )}

      <StyledContent>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <StyledLabel htmlFor="comments">
            {isFlagged
              ? "Reason for flagging"
              : "Let us flag this one, and we will bring you to the next sample!"}
          </StyledLabel>

          <StyledTextArea
            id="reason"
            {...register("reason", { required: true })}
          />

          <StyledButton type="submit">{submitButtonText}</StyledButton>
        </StyledForm>
      </StyledContent>
    </StyledFlagToPeer>
  );
};

const StyledFlagToPeer = styled.div`
  height: 100%;
  flex: 1;
  overflow: auto;
`;

const StyledHeader = styled.header<{ isFlagged: boolean }>`
  background-color: #202020;
  margin: 0 0 0 0;
  padding: 24px;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.4px;
    color: #ececec;
    margin: 0 0 2px 0;
  }

  span {
    font-style: normal;
    font-weight: ${(props) => (props.isFlagged ? "normal" : 500)};
    fonts-size: ${(props) => (props.isFlagged ? "18px" : "16px")};
    line-height: ${(props) => (props.isFlagged ? "23px" : "16px")};
    letter-spacing: 0.4px;
    color: #f8ac2f;
  }
`;

const StyledContent = styled.div`
  flex: 1;
  padding: 24px;
`;

const StyledForm = styled.form``;

const StyledLabel = styled.label`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: 0.4px;
  color: #ececec;
  display: block;
  margin: 0 0 22px 0;
`;

const StyledTextArea = styled.textarea`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.4px;
  color: #ffffff;
  padding: 14px;
  background: #464646;
  border: 1px solid #8a8a8a;
  box-sizing: border-box;
  border-radius: 3px;
  resize: vertical;
  width: 100%;
  display: block;
  margin: 0 0 0 0;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #f8ac2f;
  border-radius: 3px;
  height: 37px;
  width: 165px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #202020;
  margin: 18px 0 0 0;
`;
