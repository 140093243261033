import React from "react";
import styled from "styled-components";
import { ErrorComponent } from "../components/ErrorComponent";
import { useNavigate } from "react-router-dom";

export interface NotFoundProps {}

export const NotFound: React.FC<NotFoundProps> = (props) => {
  const navigate = useNavigate();

  return (
    <StyledNotFound className="not-found">
      <ErrorComponent
        title="We can’t find the page you were looking for!"
        description="Maybe we’ve made some changes since last time you visited! In all cases, don’t worry, you can always go back to the home."
        handleBackNavigation={() => navigate("/overview")}
      >
        <StyledContactContainer>
          <StyledText>Cant&apos;t find what you need?</StyledText>

          <StyledLink href="mailto:anwar@meetampersand.com" target="_blank">
            Contact us
          </StyledLink>
        </StyledContactContainer>
      </ErrorComponent>
    </StyledNotFound>
  );
};

const StyledNotFound = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledContactContainer = styled.div``;

const StyledLink = styled.a`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.4px;
  color: #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
`;

const StyledText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: #ececec;
  margin: 0 0 12px 0;
`;
