import * as React from "react";
import styled from "styled-components";

export function Logo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={124}
      height={136}
      viewBox="0 0 124 136"
      {...props}
    >
      <path
        fill="#7525FB"
        d="M120.434 70.565l-20.333-21.333c-.979-1.027-2.539-.89-3.535 0L78.223 65.609c-2.643-2.855-5.284-5.711-7.926-8.567 10.475-12.107 10.459-29.568-.174-41.738C58.729 2.26 38.369 2.638 25.815 13.565c-.043.038-.071.079-.11.117C12.583 24.8 13.22 42.223 22.794 55.637 3.506 69.35 1.069 98.313 16.097 116.203c16.375 19.495 46.546 16.824 61.808-2.119l14.513 15.35.315.333c.944 1 2.581.931 3.535 0l20-19.5c1.004-.978.916-2.545 0-3.535-4.865-5.259-9.73-10.519-14.597-15.777L120.434 74.1c1.032-.926.901-2.59 0-3.535zM65.698 17.908c9.976 9.537 9.643 25.25 1.178 35.435L31.679 15.298c10.466-7.315 24.625-6.373 34.019 2.61zm8.689 92.652c-13.233 17.533-38.754 18.739-53.879 3.081C5.967 98.584 8.932 71.388 25.924 59.552c3.955 4.539 8.653 8.705 12.682 12.966l35.873 37.942c-.031.036-.064.062-.092.1zM47.599 74.755L27.363 53.351c-.221-.233-.299-.297-.32-.299-.06-.101-.189-.303-.452-.688-1.592-2.335-2.916-4.877-3.972-7.495-3.975-9.854-2.161-19.251 5.214-26.373l83.181 89.913-16.44 16.03-46.975-49.684zm34.013-5.482L98.19 54.472l16.893 17.724L98.28 87.291a93527.409 93527.409 0 00-16.668-18.018z"
      />
    </svg>
  );
}

export const ComposedLogo = () => {
  return (
    <LogoContainer>
      <Logo width={233} height={229} />

      <Text>Ampersand</Text>
    </LogoContainer>
  );
};

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
`;

const Text = styled.h1`
  font-size: 68px;
  font-weight: 400;
  margin: 62px 0 0 0;
`;
