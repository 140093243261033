import {
  ACCESS_TOKEN_KEY,
  AUTH_STATE,
  AUTH_STATE_KEY,
  SHOW_APPLICATION_INSTRUCTIONS_KEY,
  USER_ID_KEY,
} from "../constants";
import { webAuth } from "../Auth0Context";

/**
 * Gets the authentication state
 */
export function isAuthenticated(): boolean {
  const cachedAuthState = localStorage.getItem(AUTH_STATE_KEY);
  return cachedAuthState === AUTH_STATE.SIGNED_IN;
}

/**
 * Convert time in seconds to human readable format
 * @param timestamp
 */
export function duration(timestamp: number): string {
  const hours = Math.floor(timestamp / 60 / 60);
  const minutes = Math.floor(timestamp / 60) - hours * 60;
  const seconds = timestamp % 60;

  const formattedHours =
    hours.toString().padStart(2, "0") + ` ${hours > 1 ? "hours" : "hour"}`;
  const formattedMinutes =
    minutes.toString().padStart(2, "0") +
    ` ${minutes > 1 ? "minutes" : "minute"}`;
  const formattedSeconds =
    seconds.toString().padStart(2, "0") +
    ` ${seconds > 1 ? "seconds" : "second"}`;

  let formatted = [];
  if (hours > 0) {
    formatted.push(formattedHours);
  }
  if (minutes > 0) {
    formatted.push(formattedMinutes);
  }
  if (seconds > 0) {
    formatted.push(formattedSeconds);
  }
  if (formatted.length > 0) {
    return formatted.join(", ");
  } else {
    return "0 seconds";
  }
}

/**
 * Get the user id stored in local storage
 */
export function getUserId(): string {
  return String(JSON.parse(localStorage.getItem(USER_ID_KEY) as string));
}

/**
 * Get the boolean value of whether or not to show application instructions
 */
export function getShowApplicationInstructionsValue(): boolean | null {
  return JSON.parse(
    localStorage.getItem(SHOW_APPLICATION_INSTRUCTIONS_KEY) as string
  );
}

/**
 * Set the boolean value of whether or not to show application instructions
 * @param value
 */
export function setShowApplicationInstructionsValue(value: boolean) {
  localStorage.setItem(
    SHOW_APPLICATION_INSTRUCTIONS_KEY,
    JSON.stringify(value)
  );
}

/**
 * The checkSession method allows you to acquire a new token from Auth0
 * for a user who is already authenticated against Auth0 for your domain.
 * The method accepts any valid OAuth2 parameters that would normally be sent to authorize.
 * If you omit them, it will use the ones provided when initializing Auth0.
 */
export async function checkSession() {
  return new Promise((resolve, reject) => {
    webAuth.checkSession({}, (error, authResult) => {
      if (error) {
        reject(error);
      } else {
        resolve(authResult);
      }
    });
  });
}

/**
 * Returns the stored access token from local storage
 */
export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}
