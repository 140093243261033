import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { Auth } from "./pages/Auth";
import { Overview } from "./pages/Overview";
import { AppLayout } from "./pages/AppLayout";
import { Feedback } from "./pages/Feedback";
import { Callback } from "./pages/Callback";
import { UnAuthenticatedRoute } from "./components/UnAuthenticatedRoute";
import { NotFound } from "./pages/NotFound";
import { SessionTimeout } from "./pages/SessionTimeout";
import { Completed } from "./pages/Completed";

function App() {
  return (
    <Routes>
      <ProtectedRoute path="/" element={<AppLayout />}>
        <Route path="overview" element={<Overview />} />
        <Route path="feedback">
          <Route path="/" element={<Feedback />} />
          <Route path="complete" element={<Completed />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </ProtectedRoute>
      <UnAuthenticatedRoute path="login" element={<Auth />} />
      <UnAuthenticatedRoute path="sign-up" element={<Auth />} />
      <UnAuthenticatedRoute path="change-password" element={<Auth />} />
      <UnAuthenticatedRoute
        path="session-timeout"
        element={<SessionTimeout />}
      />
      <UnAuthenticatedRoute path="callback" element={<Callback />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
