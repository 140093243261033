import React from "react";
import styled from "styled-components";
import KeyIcon from "./KeyIcon";

export interface GoogleSignInButtonProps
  extends React.HTMLProps<HTMLButtonElement> {}

export const GoogleSignInButton: React.FC = (props) => {
  return (
    <StyledGoogleSignInButton {...props}>
      <span>
        <KeyIcon />
      </span>

      <span>Connect with Google</span>
    </StyledGoogleSignInButton>
  );
};

const StyledGoogleSignInButton = styled.button`
  display: flex;
  background: rgba(196, 196, 196, 0.15);
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  height: 47px;
  font-weight: 500;
  font-size: 16px;
  color: #c4c4c4;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  & > span:nth-child(1) {
    width: 55px;
    border-right: 1px solid #c4c4c4;
  }

  & > span:nth-child(2) {
    width: 100%;
  }
`;
