import React from "react";
import styled from "styled-components";
import logo from "../assets/logo-mini.svg";
import overviewBannerImage from "../assets/overview_banner_image.png";
import inputImage from "../assets/input_image.png";
import outputImage from "../assets/output_image_enhanced.png";
import { Tag } from "../components/Tag";
import { Button } from "../components/Button";
import { OverviewBanner } from "../components/OverviewBanner";
import { Expert } from "../components/Expert";
import { TeamGoals } from "../components/TeamGoals";
import { Divider } from "../components/Divider";
import { InputPreview } from "../components/InputPreview";
import { OutputPreview } from "../components/OutputPreview";
import { useNavigate } from "react-router-dom";
import {
  FormKey,
  NextTestCaseKey,
  TestCasesKey,
  useFetchApplicationsForUser,
} from "../hooks";
import { getUserId } from "../utils";
import { EXPERTS, USER_APPLICATION_ID_KEY } from "../constants";
import { useQueryClient } from "react-query";
import {
  fetchFormForApplication,
  fetchNextTestCaseForApplicationForUser,
  fetchTestCasesForApplication,
} from "../api";
import { TailSpin } from "react-loading-icons";
import { NoApplicationsFound } from "../components/NoApplicationsFound";
import { MetaData } from "../types";

export const Overview = () => {
  const navigate = useNavigate();
  const userId = getUserId();
  const {
    isLoading: isLoadingUserApplications,
    data: userApplications,
  } = useFetchApplicationsForUser(userId);
  const queryClient = useQueryClient();
  const [metaData, setMetaData] = React.useState<null | MetaData>(null);

  const userApplicationId =
    userApplications && userApplications.length > 0
      ? userApplications[0].id
      : null;

  const application =
    userApplications && userApplications.length > 0
      ? userApplications[0].application
      : undefined;

  React.useEffect(() => {
    if (application) {
      setMetaData(application.metaData);
    }
  }, [application]);

  React.useEffect(() => {
    if (userApplicationId) {
      localStorage.setItem(
        USER_APPLICATION_ID_KEY,
        userApplicationId.toString()
      );
    }

    if (application && userId) {
      const applicationId = application?.id;
      queryClient.prefetchQuery([TestCasesKey, applicationId], () =>
        fetchTestCasesForApplication(applicationId).catch(console.error)
      );
      queryClient.prefetchQuery([FormKey, applicationId], () =>
        fetchFormForApplication(applicationId).catch(console.error)
      );
      queryClient.prefetchQuery([NextTestCaseKey, applicationId], () =>
        fetchNextTestCaseForApplicationForUser({
          userId,
          applicationId,
        }).catch(console.error)
      );
    }
  }, [application, queryClient, userId, userApplicationId]);

  if (isLoadingUserApplications) {
    return (
      <LoadingContainer>
        <TailSpin height="5em" width="5em" fill="#a0a0a0" stroke="#a0a0a0" />
      </LoadingContainer>
    );
  }

  if (userApplications && userApplications.length === 0) {
    return <NoApplicationsFound />;
  }

  return (
    <OverviewContainer>
      <Header>
        <div>
          <PageTitleContainer>
            <PageTitle>Study overview</PageTitle>
            <Tag text={application ? application.status : ""} />
          </PageTitleContainer>

          {metaData && <p>{metaData.summary}</p>}
        </div>

        <div>
          <FeedbackButton
            primary
            label="Open Feedback Area"
            onClick={() => navigate("/feedback")}
          />
        </div>
      </Header>

      <OverviewBannerContainer>
        <OverviewBanner
          image={overviewBannerImage}
          logo={logo}
          text={metaData ? metaData.overview : ""}
          title="Why are we doing this?"
        />
      </OverviewBannerContainer>

      <ExpertsAndGoalsContainer>
        <div>
          <Title>Your team of experts</Title>
          <ExpertsGrid>
            {EXPERTS.map((props) => (
              <Expert key={props.name} {...props} />
            ))}
          </ExpertsGrid>
        </div>

        <div>
          <Title>Team goals</Title>
          <TeamGoals assets={230} done={19} />
        </div>
      </ExpertsAndGoalsContainer>

      <Divider />

      <InputContainer>
        <Title>Input</Title>
        <InputPreview
          image={
            metaData && !!metaData.input.image
              ? metaData.input.image
              : inputImage
          }
          text="Image(s) - Brain CT scan slices (DICOM format)"
        />
      </InputContainer>

      <OutputContainer>
        <Title>Output</Title>

        {metaData && <p>{metaData.output.summary}</p>}

        <OutputPreview
          image={
            metaData && !!metaData.output.image
              ? metaData.output.image
              : outputImage
          }
          text={metaData ? metaData.output.content : ""}
        />
      </OutputContainer>

      <Divider />

      <UseCaseIdentifiedContainer>
        <Title>Use case identified</Title>
        {metaData && metaData.useCases && (
          <ul>
            {metaData.useCases.map((useCase, index) => (
              <li key={index}>{useCase}</li>
            ))}
          </ul>
        )}
      </UseCaseIdentifiedContainer>
    </OverviewContainer>
  );
};

const OverviewContainer = styled.div`
  max-width: 1128px;
  width: 100%;
  padding: 88px 32px 93px 32px;
  margin: auto;
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  margin: 0 0 53px 0;

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.4px;
    max-width: 632px;
    width: 100%;
    color: #ececec;
    margin: 0 0 16px 0;
  }

  @media (min-width: 769px) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    p {
      margin: 0 0 0 0;
    }
  }
`;

const PageTitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 0 0 19px 0;
`;

const PageTitle = styled.h1`
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 0.4px;
  color: #ececec;
  margin: 0 17px 0 0;
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: 0.4px;
  color: #ececec;
`;

const FeedbackButton = styled(Button)`
  width: 213px;
  height: 37px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
`;

const OverviewBannerContainer = styled.div`
  margin: 0 0 53px 0;
`;

const ExpertsAndGoalsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 48.5px 0;

  & > div:nth-child(1) {
    flex: 2;
  }

  & > div:nth-child(2) {
    margin: 32px 0 0 0;
    flex: 1;

    h2 {
      margin: 0 0 15px 0;
    }
  }

  @media (min-width: 577px) {
    flex-direction: row;

    & > div:nth-child(2) {
      margin: 0 0 0 0;
    }
  }
`;

const ExpertsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 18px;
  margin: 22px 0 0 0;
`;

const InputContainer = styled.div`
  margin: 21.5px 0 0 0;

  h2 {
    margin: 0 0 13px 0;
  }
`;

const OutputContainer = styled.div`
  margin: 21.5px 0 50px 0;

  h2 {
    margin: 0 0 13px 0;
  }

  p {
    max-width: 452px;
    width: 100%;
    margin: 0 0 31px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.4px;
    color: #ffffff;
  }
`;

const UseCaseIdentifiedContainer = styled.div`
  margin: 30px 0 0 0;

  ul {
    margin: 17px 0 0 0;
  }

  li {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.4px;
    color: #ffffff;
    margin: 0 0 32px 0;
  }

  li::before {
    content: "";
    display: inline-block;
    width: 7px;
    height: 15px;
    background: #ddd0f2;
    margin-inline-end: 10px;
    transform: translateY(1px);
  }
`;

const LoadingContainer = styled.div`
  height: calc(100% - 65px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
