import * as React from "react";

export function FlagIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 3.75L8.75 2.5H3.125v10.625h1.25V8.75h3.5l.25 1.25H12.5V3.75H9z"
        fill="#F8AC2F"
      />
    </svg>
  );
}
