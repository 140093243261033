import React from "react";
import styled from "styled-components";

export interface DividerProps {}

export const Divider: React.FC<DividerProps> = (props) => {
  return <StyledDivider {...props} />;
};

const StyledDivider = styled.hr`
  border: 1px solid rgba(196, 196, 196, 0.24);
`;
