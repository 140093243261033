import React from "react";
import styled from "styled-components";

export interface ButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * How large should the button be?
   */
  size?: "small" | "medium" | "large";
  /**
   * Button contents
   */
  label: string;
  /**
   * Optional click handler
   */
  onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const Button: React.FC<ButtonProps> = ({
  primary = false,
  size = "medium",
  backgroundColor,
  label,
  ...props
}) => {
  return (
    <StyledButton {...props} label={label} primary={primary}>
      {label}
    </StyledButton>
  );
};

const StyledButton = styled.button<ButtonProps>`
  width: 100%;
  height: 37px;
  color: ${(props) => (props.primary ? "#fff" : "rgba(117, 53, 247, 1)")};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.primary ? "rgba(117, 53, 247, 1)" : "#fff")};
  border-radius: 3px;
  border: none;
`;
