import React from "react";
import styled from "styled-components";
import { Logo } from "./Logo";
import { ProfileMenu } from "./ProfileMenu";
import { HorizontalNav } from "./HorizontalNav";
import { NavItemInterface } from "../types";

export interface HeaderProps {
  navItems: NavItemInterface[];
  user: { name: string; email: string };
}

export const Header: React.FC<HeaderProps> = (props) => {
  return (
    <StyledHeader>
      <LogoContainer>
        <Logo width={34} height={33} />
        Ampersand
      </LogoContainer>

      <HorizontalNav navItems={props.navItems} />

      <ProfileMenu user={props.user} />
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  display: flex;
  background: rgba(72, 73, 81, 1);
  height: 65px;
  flex-shrink: 0;

  nav {
    flex: 1;
  }
`;

const LogoContainer = styled.div`
  width: 247px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: rgba(236, 236, 236, 1);
  padding: 0 0 0 22px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 16px;
  //border-bottom: solid 5px transparent;

  letter-spacing: 0.4px;

  svg {
    margin: 0 12px 0 0;
  }
`;
