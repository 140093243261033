export const PRIMARY_NAVIGATION_ITEMS = [
  { id: "overview", value: "Overview" },
  { id: "feedback", value: "Feedback Zone" },
];

export const ACCESS_TOKEN_KEY = "ampersandai-accessToken";

export const ID_TOKEN_KEY = "ampersandai-idToken";

export const REFRESH_TOKEN_KEY = "ampersandai-refreshToken";

export const AUTH_STATE_KEY = "ampersandai-authState";

export const USER_DATA_KEY = "ampersandai-userData";

export const USER_ID_KEY = "ampersandai-userId";

export const EXPIRES_AT_KEY = "ampersandai-expires-at";

export const USER_APPLICATION_ID_KEY = "ampersandai-user-application-id";

export const SHOW_APPLICATION_INSTRUCTIONS_KEY =
  "ampersandai-show-application-instructions";

export const AUTH_STATE = {
  SIGNED_IN: "signed-in",
  SIGNED_OUT: "signed-out",
};

export const EXPERTS = [
  {
    name: "Dr. Mulumba",
    progress: 12,
  },
  {
    name: "Dr. Ribosblatine",
    progress: 0,
  },
  {
    name: "Dr. Robinson",
    progress: 5,
  },
  {
    name: "Dr. Parashar",
    progress: 30,
  },
];

export const APPLICATION_TIMEOUT = 1000 * 60 * 15; // 15 minutes

export const ERROR_CODES = {
  UNEXPECTED_ERROR: {
    TITLE: "Unexpected error",
    DESCRIPTION: "An unexpected error has occurred. Please try again.",
  },
  UNAUTHORIZED: {
    TITLE: "Access denied",
    DESCRIPTION: "You are not authorized to access Ampersand AI at this time.",
  },
  NO_APPLICATIONS: {
    TITLE: "No applications found",
    DESCRIPTION:
      "Looks like no applications have been configured for your account as yet. Please try again later.",
  },
};
