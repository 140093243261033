import React from "react";
import styled from "styled-components";

export interface TagProps {
  text: string;
}

export const Tag: React.FC<TagProps> = (props) => {
  return (
    <StyledTag>
      <span>{props.text}</span>
    </StyledTag>
  );
};

const StyledTag = styled.div`
  width: 105px;
  height: 28px;
  border: solid 1px rgba(221, 208, 242, 1);
  color: rgba(188, 177, 206, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.4px;
  flex-shrink: 0;

  span {
    display: block;
    margin: auto;
  }
`;
