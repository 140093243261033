import React from "react";
import styled from "styled-components";
import { Progress } from "./Progress";

export interface ExpertProps {
  name: string;
  progress: number;
}

export const Expert: React.FC<ExpertProps> = (props) => {
  return (
    <StyledExpert>
      <Name>{props.name}</Name>
      <Progress percentage={props.progress} />
    </StyledExpert>
  );
};

const StyledExpert = styled.div``;

const Name = styled.span`
  display: block;
  margin: 0 0 9px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.4px;
  color: #ececec;
`;
