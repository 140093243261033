import axios from "axios";
import {
  Form,
  CreateResponsePayload,
  Responses,
  TestCase,
  TestCases,
  UserApplications,
  UpdateResponsePayload,
  CreateFlaggedResponsePayload,
  User,
} from "../types";
import { getAccessToken } from "../utils";
import { checkSession } from "../Auth0Context";

// const API_ORIGIN =
//   process.env.NODE_ENV === "development"
//     ? "/api"
//     : "https://ampersand-api.herokuapp.com";

const API_ORIGIN = "https://ampersand-api.herokuapp.com";

axios.defaults.headers.common["Authorization"] = `Bearer ${getAccessToken()}`;

export async function get(url: string) {
  await checkSession();
  return axios.get(url).then((response) => response.data);
}

export async function post(url: string, data: any) {
  await checkSession();
  return axios.post(url, data).then((response) => response.data);
}

export async function patch(url: string, data: any) {
  await checkSession();
  return axios.patch(url, data).then((response) => response.data);
}

/**
 * Fetch all applications for the authenticated user.
 * @param userId
 */
export async function fetchApplicationsForUser(
  userId: string
): Promise<UserApplications> {
  return get(`${API_ORIGIN}/users/${userId}/applications/`);
}

/**
 * Get all the test case responses for the authenticated user
 * @param userId
 * @param applicationId
 */
export async function fetchResponsesForUserForApplication({
  userId,
  applicationId,
}: {
  userId: string | number;
  applicationId: string | number;
}): Promise<Responses> {
  return get(
    `${API_ORIGIN}/users/${userId}/applications/${applicationId}/responses`
  );
}

/**
 * Fetches the form for the current application
 * @param applicationId
 */
export async function fetchFormForApplication(
  applicationId: string | number
): Promise<Form> {
  return get(`${API_ORIGIN}/applications/${applicationId}/form`);
}

/**
 * Flag a test case response for the authenticated in user
 * @param payload
 */
export async function createFlaggedResponse(
  payload: CreateFlaggedResponsePayload
) {
  return post(`${API_ORIGIN}/responses/flag`, payload);
}
/**
 * Creates a test case response for the authenticated in user
 * @param payload
 */
export async function createResponse(payload: CreateResponsePayload) {
  return post(`${API_ORIGIN}/responses`, payload);
}

/**
 * Update a test case response for the authenticated in user
 * @param payload
 * @param responseId
 */
export async function updateResponse(
  payload: UpdateResponsePayload,
  responseId: string
) {
  return patch(`${API_ORIGIN}/responses/${responseId}`, payload);
}

/**
 * Update the total time the authenticated user has spent on the application
 * @param payload
 * @param userApplicationId
 */
export async function updateTotalTimeForApplicationForUser(
  payload: {
    totalTime: number; // seconds
  },
  userApplicationId: string | number
): Promise<{
  id: number;
  totalTime: number;
  updatedAt: Date | string | null;
}> {
  return patch(
    `${API_ORIGIN}/application-users/${userApplicationId}/total-time`,
    payload
  );
}

/**
 * Update user application
 * @param payload
 * @param userApplicationId
 */
export async function updateApplicationForUser(
  payload: {
    hasAccepted?: boolean;
    hasStarted?: boolean;
    isCompleted?: boolean;
  },
  userApplicationId: string | number
): Promise<{
  id: number;
  hasAccepted: boolean;
  hasStarted: boolean;
  isCompleted: boolean;
  totalTime: number;
  createdAt: Date | string | null;
  updatedAt: Date | string | null;
}> {
  return patch(`${API_ORIGIN}/application-users/${userApplicationId}`, payload);
}

/**
 * Update a test case response for the authenticated in user
 * @param responseId
 */
export async function fetchResponseById(responseId: string) {
  return get(`${API_ORIGIN}/responses/${responseId}`);
}

/**
 * Fetch all the test cases for the requested application
 * @param applicationId
 */
export async function fetchTestCasesForApplication(
  applicationId: string | number
): Promise<TestCases> {
  return get(`${API_ORIGIN}/applications/${applicationId}/testCases`);
}

/**
 * Fetch the next available test case for the authenticated user, for the given application.
 * @param userId
 * @param applicationId
 */
export async function fetchNextTestCaseForApplicationForUser({
  userId,
  applicationId,
}: {
  userId: string | number;
  applicationId: string | number;
}): Promise<TestCase> {
  return get(
    `${API_ORIGIN}/applications/${applicationId}/users/${userId}/testcases/next`
  );
}

/**
 * Authorize an authenticated user with Ampersand's services
 */
export async function authorizeUser(): Promise<User> {
  return get(`${API_ORIGIN}/authorize`);
}
