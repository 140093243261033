import React from "react";
import styled from "styled-components";
import { NavItemInterface } from "../types";
import { NavItem } from "./NavItem";

export interface HorizontalNavProps {
  navItems: NavItemInterface[];
}

export const HorizontalNav: React.FC<HorizontalNavProps> = (props) => {
  return (
    <StyledHorizontalNav {...props}>
      {props.navItems.map((navItem) => (
        <NavItem key={navItem.id} to={navItem.id}>
          {navItem.value}
        </NavItem>
      ))}
    </StyledHorizontalNav>
  );
};

const StyledHorizontalNav = styled.nav`
  display: flex;
  height: 65px;
  overflow: auto;
`;
