import React from "react";
import styled from "styled-components";

export interface OverviewBannerProps {
  image: any;
  title: string;
  text: string;
  logo: any;
}

export const OverviewBanner: React.FC<OverviewBannerProps> = (props) => {
  return (
    <StyledOverviewBanner>
      <img src={props.image} alt="" />
      <Content>
        <div>
          <Title>{props.title}</Title>
          <p>{props.text}</p>
        </div>
        <div>
          <Title>Hosted by</Title>
          <img src={props.logo} alt="" />
        </div>
      </Content>
    </StyledOverviewBanner>
  );
};

const StyledOverviewBanner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1128px;
  width: 100%;
  background: rgba(0, 0, 0, 0.32);
  border: 1px solid #8a8a8a;
  box-sizing: border-box;
  border-radius: 3px;

  & > img {
    object-fit: cover;
    width: 100%;
    height: 278px;
  }

  @media (min-width: 769px) {
    grid-template-columns: auto 1fr;
    height: 278px;

    & > img {
      object-fit: cover;
      width: 314px;
      height: 100%;
    }
  }
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: 0.4px;
  color: #ddd0f2;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 41px 45px 41px 45px;
  overflow: hidden;

  div:nth-child(1) {
    margin: 0 0 0 0;
    overflow: auto;

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0.4px;
      color: #ffffff;
      width: 100%;
      margin: 11px 0 32px 0;
      text-overflow: ellipsis;
    }
  }

  div:nth-child(2) {
    img {
      display: block;
      margin: 17px 0 0 0;
    }
  }

  @media (min-width: 769px) {
    flex-direction: row;
    div:nth-child(1) {
      margin: 0 69px 0 0;
      overflow: auto;

      p {
        max-width: 404px;
        margin: 11px 0 0 0;
      }
    }

    div:nth-child(2) {
      img {
        display: block;
        margin: 17px 0 0 0;
      }
    }
  }
`;
