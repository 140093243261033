import * as React from "react";

export function LogoutIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.041 4.958l-.999 1 1.828 1.834H5.666v1.416h7.204l-1.828 1.828.999 1.006L15.583 8.5 12.04 4.958zM2.833 3.542h5.666V2.125H2.833a1.42 1.42 0 00-1.417 1.417v9.916c0 .78.638 1.417 1.417 1.417h5.666v-1.417H2.833V3.542z"
        fill="#F8AC2F"
      />
    </svg>
  );
}
