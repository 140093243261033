import React from "react";
import styled from "styled-components";
import { Button } from "./Button";
import InfoIcon from "./InfoIcon";
import { useFetchApplicationsForUser } from "../hooks";
import { getUserId } from "../utils";
import { Instructions as InstructionsType } from "../types";

export interface InstructionsProps {
  onStart: () => void;
}

export const Instructions: React.FC<InstructionsProps> = (props) => {
  // eslint-disable-next-line
  const { isLoading, isFetching, isError, data } = useFetchApplicationsForUser(
    getUserId()
  );
  const [
    instructions,
    setInstructions,
  ] = React.useState<InstructionsType | null>(null);

  const userApplication = data && data.length > 0 ? data[0] : null;

  console.log(userApplication);

  React.useEffect(() => {
    if (userApplication) {
      setInstructions(userApplication.application.metaData.instructions);
    }
  }, [userApplication]);

  if (isLoading) {
    return null;
  }

  const buttonText =
    userApplication && userApplication.hasStarted
      ? "Continue"
      : "Ready to start";

  return (
    <StyledInstructions className="instructions">
      <Header className="instuctions__header">
        <Title>
          <InfoIcon /> Instructions
        </Title>

        <HeaderText>{instructions?.header}</HeaderText>
      </Header>

      <Body className="instructions__body">
        {instructions && (
          <ul>
            {instructions?.steps.map((text, index) => (
              <li key={index}>{text}</li>
            ))}
          </ul>
        )}
      </Body>

      <Footer className="instructions__footer">
        <StyledButton primary label={buttonText} onClick={props.onStart} />
      </Footer>
    </StyledInstructions>
  );
};

const StyledInstructions = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: hidden;
`;

const Header = styled.header`
  padding: 24px 24px 49px 24px;
  border-bottom: 1px solid #000000;
  background-color: #202020;

  position: sticky;
  top: 0;
  z-index: 10;
`;

const Body = styled.section`
  flex: 1;
  padding: 45px 36px 31px 0;
  overflow: auto;

  ul {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.4px;
    color: #ececec;

    li {
      margin: 0 0 23px 0;
      padding: 0 0 0 21px;
    }

    li::before {
      content: "";
      display: inline-block;
      width: 4px;
      height: 14px;
      background: #ddd0f2;
      //margin-inline-end: -21px;
      transform: translateX(-21px);
    }
  }
`;

const Footer = styled.footer`
  padding: 24px;
  border-top: 1px solid #000000;
  position: sticky;
  bottom: 0;
  background-color: #202020;
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.4px;
  color: #ddd0f2;
  margin: 0 0 14px 0;
  display: flex;
  align-items: center;

  svg {
    margin: 0 11px 0 0;
  }
`;

const HeaderText = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: 0.4px;
  color: #ffffff;
`;

const StyledButton = styled(Button)`
  width: 165px;
`;
