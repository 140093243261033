import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

export interface NavItemProps {
  to: string;
}

export const NavItem: React.FC<NavItemProps> = ({ children, ...props }) => {
  // let match = useMatch(props.to);
  let location = useLocation();
  let match = location.pathname.includes(props.to);

  return (
    // @ts-ignore
    <StyledNavItem {...props} active={match ? 1 : 0}>
      {children}
    </StyledNavItem>
  );
};

const StyledNavItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 0 60px;
  height: 65px;
  color: rgba(255, 255, 255, 1);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.4px;
  position: relative;
  background: ${(props) =>
    // @ts-ignore
    props.active ? "rgba(255, 255, 255, 0.1)" : "transparent"};
  flex-shrink: 0;

  &:after {
    content: "";
    display: block;
    height: 5px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: ${(props) =>
      // @ts-ignore
      props.active ? "rgba(248, 172, 47, 1)" : "transparent"};
    color: rgba(255, 255, 255, 1);
  }
`;
