import React from "react";
import styled from "styled-components";
import { Header } from "../components/Header";
import { PRIMARY_NAVIGATION_ITEMS, USER_DATA_KEY } from "../constants";
import { Outlet } from "react-router-dom";

export interface AppLayoutProps {}

export const AppLayout: React.FC<AppLayoutProps> = (props) => {
  const userState = JSON.parse(localStorage.getItem(USER_DATA_KEY) as string);

  return (
    <StyledAppLayout>
      <Header navItems={PRIMARY_NAVIGATION_ITEMS} user={userState} />
      <ScrollContainer>
        <Outlet />
      </ScrollContainer>
    </StyledAppLayout>
  );
};

const StyledAppLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const ScrollContainer = styled.div`
  overflow: auto;
  flex: 1;
`;
