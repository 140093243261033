import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryObserverOptions } from "react-query/types/core/types";
import {
  createFlaggedResponse,
  createResponse,
  fetchApplicationsForUser,
  fetchFormForApplication,
  fetchNextTestCaseForApplicationForUser,
  fetchResponsesForUserForApplication,
  fetchTestCasesForApplication,
  updateApplicationForUser,
  updateResponse,
} from "../api";
import {
  Form,
  CreateResponsePayload,
  Responses,
  TestCase,
  UserApplications,
} from "../types";
import { useRecoilState, useSetRecoilState } from "recoil";
import { FeedbackState } from "../state";

export const ApplicationsForUserKey = "applicationsForUser";
export function useFetchApplicationsForUser(
  userId: string,
  options?: QueryObserverOptions | any
) {
  return useQuery<UserApplications, Error>(
    [ApplicationsForUserKey, userId],
    () => fetchApplicationsForUser(userId),
    {
      enabled: !!userId,
      ...options,
    }
  );
}

export const ResponsesKey = "responses";
export function useFetchResponsesForUserForApplication(
  {
    userId,
    applicationId,
  }: {
    userId: string | number;
    applicationId: string | number;
  },
  options?: QueryObserverOptions | any
) {
  return useQuery<Responses, Error>(
    [ResponsesKey, applicationId],
    () => fetchResponsesForUserForApplication({ userId, applicationId }),
    {
      enabled: !!userId && !!applicationId,
      ...options,
    }
  );
}

export const NextTestCaseKey = "nextTestCase";
export function useFetchNextTestCaseForApplicationForUser(
  {
    userId,
    applicationId,
  }: {
    userId: string | number;
    applicationId: string | number;
  },
  options?: QueryObserverOptions | any
) {
  return useQuery<TestCase, Error>(
    [NextTestCaseKey, applicationId],
    () => fetchNextTestCaseForApplicationForUser({ userId, applicationId }),
    {
      enabled: !!userId && !!applicationId,
      ...options,
    }
  );
}

export const FormKey = "form";
export function useFetchFormForApplication(
  applicationId: string | number,
  options?: QueryObserverOptions | any
) {
  return useQuery<Form, Error>(
    [FormKey, applicationId],
    () => fetchFormForApplication(applicationId),
    {
      enabled: !!applicationId,
      ...options,
    }
  );
}

export const TestCasesKey = "testCases";
export function useFetchTestCasesForApplication(
  applicationId: string | number,
  options?: QueryObserverOptions | any
) {
  return useQuery<TestCase[], Error>(
    [TestCasesKey, applicationId],
    () => fetchTestCasesForApplication(applicationId),
    {
      enabled: !!applicationId,
      ...options,
    }
  );
}

export function useCreateResponse(applicationId: number) {
  const queryClient = useQueryClient();
  const responsesQueryKey = [ResponsesKey, applicationId];
  const nextTestCaseQueryKey = [NextTestCaseKey, applicationId];

  return useMutation(
    (responsePayload: CreateResponsePayload) => createResponse(responsePayload),
    {
      // When mutate is called:
      onMutate: async (responsePayload) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(responsesQueryKey);

        // Snapshot the previous value
        const previousResponses = queryClient.getQueryData(responsesQueryKey);

        // Optimistically update to the new value
        queryClient.setQueryData(responsesQueryKey, (old: any) => {
          return [...old, { response: responsePayload.response }];
        });

        // Return a context object with the snapshotted value
        return { previousResponses };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, responsePayload, context: any) => {
        queryClient.setQueryData(responsesQueryKey, context.previousResponses);
      },
      // Always refetch after error or success:
      onSettled: (resp) => {
        queryClient.invalidateQueries(responsesQueryKey);
        queryClient.invalidateQueries(nextTestCaseQueryKey);
      },
    }
  );
}

export function useUpdateResponse(applicationId: number) {
  const queryClient = useQueryClient();
  const responsesQueryKey = ["responses", applicationId];
  const setFeedbackState = useSetRecoilState(FeedbackState);

  return useMutation(
    ({
      id,
      response,
      timeSpent,
      index,
    }: {
      id: string;
      timeSpent: number;
      response: any;
      index: number;
    }) => updateResponse({ timeSpent, response }, id),
    {
      // When mutate is called:
      onMutate: async (responsePayload) => {
        const responses = queryClient.getQueryData([
          "responses",
          applicationId,
        ]) as any[];
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(responsesQueryKey);
        // Snapshot the previous value
        const previousResponses = queryClient.getQueryData(responsesQueryKey);
        // Optimistically update to the new value
        queryClient.setQueryData(responsesQueryKey, (old: any) => {
          return old.map((response: any) => {
            if (response.id === responsePayload.id) {
              response.response = responsePayload.response;
            }
            return response;
          });
        });
        // manually set the next test case cache to the next response
        if (responsePayload.index + 1 !== responses.length) {
          const nextResponseIndex = responsePayload.index + 1;
          // const { testCase, response, id } = responses[nextResponseIndex];
          // update atom state
          setFeedbackState({
            previousResponse: {
              ...responses[nextResponseIndex],
              index: nextResponseIndex,
            },
          });
          // update next test case cache
          // queryClient.setQueryData(
          //   ["nextTestCase", applicationId],
          //   () => testCase
          // );
        }
        // reset feedback state and fetch next test case when we reached the last response
        if (responsePayload.index + 1 === responses.length) {
          queryClient.invalidateQueries([NextTestCaseKey, applicationId]);
          setFeedbackState({ previousResponse: null });
        }
        // Return a context object with the snapshotted value
        return { previousResponses };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, responsePayload, context: any) => {
        queryClient.setQueryData(responsesQueryKey, context.previousResponses);
      },
      // Always refetch after error or success:
      onSettled: async (resp) => {
        // invalidate all responses
        queryClient.invalidateQueries(responsesQueryKey);
      },
    }
  );
}

export function useCreateFlaggedResponse(applicationId: number) {
  const queryClient = useQueryClient();
  const responsesQueryKey = [ResponsesKey, applicationId];
  const [feedbackState, setFeedbackState] = useRecoilState(FeedbackState);

  return useMutation(
    ({
      flaggedReason,
      userId,
      formId,
      testCaseId,
      timeSpent,
      id,
    }: {
      flaggedReason: string;
      userId: number;
      formId: number;
      testCaseId: number;
      timeSpent: number;
      id: number | null;
    }) =>
      createFlaggedResponse({
        applicationId,
        flaggedReason,
        userId,
        formId,
        testCaseId,
        timeSpent,
      }),
    {
      // When mutate is called:
      onMutate: async (responsePayload) => {
        const responses = queryClient.getQueryData([
          "responses",
          applicationId,
        ]) as any[];
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(responsesQueryKey);
        // Snapshot the previous value
        const previousResponses = queryClient.getQueryData(responsesQueryKey);
        // Optimistically update to the new value if there is a response id
        if (responsePayload.id) {
          queryClient.setQueryData(responsesQueryKey, (old: any) => {
            return old.map((response: any) => {
              if (response.id === responsePayload.id) {
                response.flaggedReason = responsePayload.flaggedReason;
              }
              return response;
            });
          });
          // manually set the next test case cache to the next response
          if (
            feedbackState.previousResponse &&
            feedbackState.previousResponse.index + 1 !== responses.length
          ) {
            const nextResponseIndex = feedbackState.previousResponse.index + 1;
            // update atom state
            setFeedbackState({
              previousResponse: {
                ...responses[nextResponseIndex],
                index: nextResponseIndex,
              },
            });
            // update next test case cache
            // queryClient.setQueryData(
            //   ["nextTestCase", applicationId],
            //   () => testCase
            // );
          }
          // reset feedback state and fetch next test case when we reached the last response
          if (
            feedbackState.previousResponse &&
            feedbackState.previousResponse.index + 1 === responses.length
          ) {
            // queryClient.invalidateQueries(["nextTestCase", applicationId]);
            setFeedbackState({ previousResponse: null });
          }
          // Return a context object with the snapshotted value
        }

        return { previousResponses };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, responsePayload, context: any) => {
        queryClient.setQueryData(responsesQueryKey, context.previousResponses);
      },
      // Always refetch after error or success:
      onSettled: async (resp) => {
        // invalidate all responses
        queryClient.invalidateQueries(responsesQueryKey);
        queryClient.invalidateQueries([NextTestCaseKey, applicationId]);
      },
    }
  );
}

export function useUpdateApplicationForUser() {
  return useMutation(
    ({
      payload,
      userApplicationId,
    }: {
      payload: {
        hasAccepted?: boolean;
        hasStarted?: boolean;
        isCompleted?: boolean;
      };
      userApplicationId: string | number;
    }) => updateApplicationForUser(payload, userApplicationId)
  );
}
