import React, { createContext } from "react";
import auth0, { Auth0DecodedHash, Auth0UserProfile } from "auth0-js";
import config from "./auth0-config";
import {
  ACCESS_TOKEN_KEY,
  AUTH_STATE,
  AUTH_STATE_KEY,
  EXPIRES_AT_KEY,
  ID_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  USER_APPLICATION_ID_KEY,
  USER_DATA_KEY,
  USER_ID_KEY,
} from "./constants";
import axios from "axios";
import { updateTotalTimeForApplicationForUser } from "./api";
import { TimerContext } from "./TimerContext";

interface Auth0ContextInterface {
  webAuth: auth0.WebAuth;
  parseHash: () => Promise<Auth0UserProfile>;
  logout: (returnTo?: string) => void;
  checkSession: () => Promise<any>;
}

export const webAuth = new auth0.WebAuth({ ...config });

export const Auth0Context = createContext({} as Auth0ContextInterface);

export const Auth0Provider: React.FC = (props) => {
  const { TimeMe } = React.useContext(TimerContext);

  async function logout(returnTo: string = "/login") {
    const userApplicationId = localStorage.getItem(USER_APPLICATION_ID_KEY);

    TimeMe.stopTimer("app");

    if (userApplicationId) {
      const timeSpentOnApp = TimeMe.getTimeOnPageInSeconds("app");

      await updateTotalTimeForApplicationForUser(
        {
          totalTime: Math.round(Number(timeSpentOnApp)),
        },
        userApplicationId
      );
    }

    removeAuthState();

    webAuth.logout({
      returnTo: `${window.location.origin}${returnTo}`,
      clientID: config.clientID,
    });
  }

  return (
    <Auth0Context.Provider
      value={{
        webAuth,
        parseHash,
        logout,
        checkSession,
      }}
    >
      {props.children}
    </Auth0Context.Provider>
  );
};

export function parseHash(): Promise<Auth0UserProfile> {
  return new Promise((resolve, reject) => {
    webAuth.parseHash(
      { hash: window.location.hash },
      function (err, authResult) {
        if (err) {
          return reject(err);
        }

        // store the accessToken, idToken, authState and userData
        storeAuthState(authResult);

        webAuth.client.userInfo(
          authResult?.accessToken as string,
          function (err, user) {
            if (err) {
              return reject(err);
            }

            // store the user's information
            localStorage.setItem(USER_DATA_KEY, JSON.stringify(user));
            resolve(user);
          }
        );
      }
    );
  });
}

export function storeAuthState(authResult: Auth0DecodedHash | null) {
  // Set the time that the Access Token will expire at (2 hours)
  // @ts-ignore
  let expiresAt = new Date().getTime() + authResult?.expiresIn * 1000;
  localStorage.setItem(ID_TOKEN_KEY, authResult?.idToken as string);
  localStorage.setItem(ACCESS_TOKEN_KEY, authResult?.accessToken as string);
  localStorage.setItem(AUTH_STATE_KEY, AUTH_STATE.SIGNED_IN);
  localStorage.setItem(EXPIRES_AT_KEY, JSON.stringify(expiresAt));
}

export function removeAuthState(): void {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(ID_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(USER_DATA_KEY);
  localStorage.removeItem(USER_ID_KEY);
  localStorage.removeItem(EXPIRES_AT_KEY);
  localStorage.removeItem(USER_APPLICATION_ID_KEY);
  localStorage.setItem(AUTH_STATE_KEY, AUTH_STATE.SIGNED_OUT);
}

export function checkSession(): Promise<any> {
  return new Promise((resolve, reject) => {
    let expiresAt = Number(localStorage.getItem(EXPIRES_AT_KEY));
    const isAccessTokenExpired = new Date().getTime() > expiresAt;

    if (!isAccessTokenExpired) {
      const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      return resolve({ accessToken });
    }

    webAuth.checkSession({}, (err, authResult) => {
      if (err) {
        console.log("session error", err);

        if (err.code && err.code === "login_required") {
          webAuth.logout({
            returnTo: `${window.location.origin}/session-timeout`,
            clientID: config.clientID,
          });

          removeAuthState();
        }

        return reject(err);
      }

      const { accessToken } = authResult;
      // set authorization header
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      // update local storage with new auth result data
      storeAuthState(authResult);
      resolve(authResult);
    });
  });
}
