import React from "react";
import styled from "styled-components";
import { Progress } from "../components/Progress";
import { Button } from "../components/Button";
import { useNavigate } from "react-router-dom";
import { duration, getUserId } from "../utils";
import {
  useFetchApplicationsForUser,
  useFetchResponsesForUserForApplication,
  useFetchTestCasesForApplication,
  useUpdateApplicationForUser,
} from "../hooks";
import { USER_APPLICATION_ID_KEY } from "../constants";
import { FinishedPageMetaData } from "../types";

export interface CompletedProps {}

export const Completed: React.FC<CompletedProps> = (props) => {
  const navigate = useNavigate();

  const updateApplicationForUserMutation = useUpdateApplicationForUser();

  const {
    data: applications,
    isLoading: isLoadingApplications, // eslint-disable-line
  } = useFetchApplicationsForUser(getUserId());

  const application =
    applications && applications.length > 0
      ? applications[0].application
      : null;

  const userApplicationId =
    applications && applications.length > 0 ? applications[0].id : null;

  const applicationId = application?.id ?? "";

  const {
    data: testCases,
    isLoading: isLoadingTestCases, // eslint-disable-line
  } = useFetchTestCasesForApplication(applicationId);

  const {
    data: responses,
    isLoading: isLoadingResponses, // eslint-disable-line
  } = useFetchResponsesForUserForApplication({
    userId: getUserId(),
    applicationId,
  });

  const [timeSpentOnAllTestCases, setTimeSpentOnAllTestCases] = React.useState<
    null | any
  >(null);

  const [metaData, setMetaData] = React.useState<FinishedPageMetaData | null>(
    null
  );

  React.useEffect(() => {
    if (application) {
      setMetaData(application.metaData.finishedPageMetadata);
    }
  }, [application]);

  React.useEffect(() => {
    if (responses) {
      const timeSpent = responses.reduce((acc, response) => {
        return acc + response.timeSpent;
      }, 0);

      setTimeSpentOnAllTestCases(timeSpent);
    }
  }, [responses]);

  React.useEffect(() => {
    if (userApplicationId) {
      localStorage.setItem(
        USER_APPLICATION_ID_KEY,
        userApplicationId.toString()
      );

      updateApplicationForUserMutation.mutate({
        payload: { isCompleted: true },
        userApplicationId,
      });
    }
  }, [userApplicationId]); // eslint-disable-line

  return (
    <StyledCompleted className="completed">
      <Wrapper>
        <StyledProgressContainer>
          <p>
            {responses?.length}/{testCases?.length} - Total length{" "}
            {timeSpentOnAllTestCases &&
              duration(Math.round(timeSpentOnAllTestCases))}
          </p>
          <Progress percentage={100} />
        </StyledProgressContainer>

        <MessageContainer>
          <h2>Completed! Thank you so much.</h2>

          {metaData && <p>{metaData.completionMessage}</p>}

          {metaData && (
            <ul>
              {metaData.nextSteps.map((nextStep, index) => (
                <li key={index}>{nextStep}</li>
              ))}
            </ul>
          )}

          <StyledButton
            primary
            label="Back to overview"
            onClick={() => navigate("/overview")}
          />
        </MessageContainer>
      </Wrapper>
    </StyledCompleted>
  );
};

const StyledCompleted = styled.div`
  background: #202020;
  height: 100%;
  overflow: auto;
`;

const Wrapper = styled.div`
  max-width: 1128px;
  width: 100%;
  padding: 88px 32px 93px 32px;
  margin: auto;
`;

const StyledProgressContainer = styled.div`
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(255, 255, 255, 0.51);
    margin: 0 0 10px 0;
  }
`;

const MessageContainer = styled.div`
  border-bottom: 1px solid rgba(196, 196, 196, 0.24);

  h2 {
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 0.4px;
    color: #ececec;
    margin: 29px 0 44px 0;
  }

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.4px;
    color: #ececec;
    max-width: 591px;
    width: 100%;
    margin: 0 0 59px 0;
  }

  ul {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.4px;
    color: #ffffff;
    display: flex;
    margin: 0 0 34px 0;

    li {
      margin: 0 100px 0 0;
    }

    li:nth-last-child(1) {
      margin: 0;
    }

    li::before {
      content: "";
      display: inline-block;
      width: 7px;
      height: 15px;
      background: #ddd0f2;
      margin-inline-end: 10px;
      transform: translateY(1px);
    }
  }
`;

const StyledButton = styled(Button)`
  width: 141px;
  margin: 0 0 88px 0;
`;
