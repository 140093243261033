import "survey-react/modern.css";

import React from "react";
import styled from "styled-components";
import capitalize from "lodash/capitalize";
import * as Survey from "survey-react";
import { SurveyModel } from "survey-react";
import { FlagIcon } from "./FlagIcon";
import InfoIcon from "./InfoIcon";
import { FlagToPeer } from "./FlagToPeer";
import { BackArrowIcon } from "./BackArrowIcon";
import { useRecoilValue } from "recoil";
import { FeedbackState } from "../state";

Survey.StylesManager.applyTheme("modern");

export interface SurveyProps {
  json: any;
  onComplete: (json: any) => void;
  onBack: () => any; // return a data object to populate the form
  onSkip: () => void;
  title: string;
  onInformationButtonClick: () => void;
}

export const SurveyJS: React.FC<SurveyProps> = (props) => {
  const [model, setModel] = React.useState<any | null>(null);
  const [flagToPeer, setFlagToPeer] = React.useState(false);
  const feedbackState = useRecoilValue(FeedbackState);
  const isFlagged =
    feedbackState.previousResponse && feedbackState.previousResponse.isFlagged;

  const memoizedRenderSurvey = React.useCallback(renderSurvey, [
    feedbackState,
    props.json,
  ]);

  React.useEffect(() => {
    memoizedRenderSurvey();
  }, [props.json, memoizedRenderSurvey]);

  if (!model) {
    return null;
  }

  function onComplete(survey: SurveyModel, options: any) {
    const surveyJson = Object.assign({}, survey.data);
    props.onComplete(surveyJson);
    // https://github.com/surveyjs/survey-library/issues/1855.
    survey.clear();
    survey.render();
    renderSurvey(surveyJson);
  }

  function renderSurvey(data?: any) {
    const { previousResponse } = feedbackState;
    const survey = new Survey.Model(props.json);
    survey.data = previousResponse ? previousResponse.response : {};
    survey.completeText = "Next";
    survey.onUpdateQuestionCssClasses.add((survey, options) => {
      let classes = options.cssClasses;
      classes.root = "ampersand-ai-root";
      classes.title = "ampersand-ai-title";
      classes.item = "ampersand-ai-item";
      classes.label = "ampersand-ai-label";
      classes.content += " ampersand-ai-question__content";
      classes.error.root += " ampersand-ai-question__erbox";
      classes.mainRoot += " ampersand-ai-question";
      if (options.question.isRequired) {
        classes.title += " ampersand-ai-title-required";
        classes.root += " ampersand-ai-root-required";
      }
      if (options.question.getType() === "checkbox") {
        classes.root += " ampersand-ai-root-checkbox";
      }
    });
    setModel(survey);
  }

  function handleBackNavigation() {
    if (flagToPeer) {
      setFlagToPeer(false);
    } else {
      props.onBack();
      renderSurvey();
    }
  }

  function formatTitle(title: string) {
    if (!title) {
      return "";
    }

    return title
      .split(" ")
      .map((str) => capitalize(str))
      .join(" ");
  }

  const title = feedbackState.previousResponse
    ? feedbackState.previousResponse.testCase.name
    : props.title;

  return (
    <StyledSurvey className="survey">
      <StyledHeader className="survey__header">
        <TitleContainer>
          <button onClick={handleBackNavigation}>
            <BackArrowIcon />
          </button>

          <h2>{formatTitle(title)}</h2>
        </TitleContainer>

        <InformationButton onClick={props.onInformationButtonClick}>
          <InfoIcon />
        </InformationButton>
      </StyledHeader>

      {!flagToPeer && !isFlagged && (
        <StyledBody className="survey_body">
          <Survey.Survey model={model} onComplete={onComplete} />
        </StyledBody>
      )}

      {(flagToPeer || isFlagged) && (
        <FlagToPeer callback={() => setFlagToPeer(false)} />
      )}

      {!flagToPeer && !isFlagged && (
        <StyledFooter className="survey__footer">
          <p>Not Sure?</p>

          <button onClick={() => setFlagToPeer(true)}>
            <FlagIcon /> Flag &amp; tell us why
          </button>
        </StyledFooter>
      )}
    </StyledSurvey>
  );
};

const StyledHeader = styled.header`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 0.4px;
  color: #ddd0f2;
  height: 60px;
  //margin: 13px 0 54px 0;
  padding: 13px 28px 0 17px;
  position: sticky;
  top: 0;
  background: #202020;
  z-index: 10;

  h2 {
    cursor: pointer;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  button {
    display: flex;
    align-items: center;
    margin: 0 14px 0 0;
  }
`;

const StyledSurvey = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  height: 100%;

  .sv-container,
  .sv-question {
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif !important;
  }

  .sv-body__page,
  .sv-body__footer {
    margin: 0;
  }

  textarea {
    margin: 0;
    background: #464646;
    border: 1px solid #8a8a8a;
    box-sizing: border-box;
    border-radius: 3px;
    min-height: 159px;
    height: 159px;
    width: 95%;
    resize: vertical;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.4px;
    padding: 14px;

    color: #ffffff;
  }

  .sv-row:not(:last-child) {
    padding: 0 0 3rem 0;
  }

  .ampersand-ai-question {
    position: relative;
    padding: 0.55rem 5% 0.55em 8%;
  }

  .ampersand-ai-question:before {
    content: "";
    height: 100%;
    width: 4px;
    background: #7535f7;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .ampersand-ai-question__erbox {
    display: none;
  }

  .ampersand-ai-question__content {
    margin: 0 0 0 0.55em;

    input {
      background: rgba(196, 196, 196, 0.15);
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
      border-radius: 3px;
      //width: 100%;
      //height: 47px;
      padding: 1rem;
      color: #fff;
    }
  }

  .sv-body__footer {
    display: flex;
    width: 100%;
    margin: 47px 0 0 0;
    padding: calc(10% + 0.55em);

    .sv-footer__complete-btn {
      background: #7535f7;
      border-radius: 3px;
      width: 165px;
      height: 37px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.4px;
    }
  }

  .ampersand-ai-title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.4px;
    color: #ffffff;
    padding: 0.55em;
  }

  .ampersand-ai-root {
    display: flex;
    margin-top: 0;

    .ampersand-ai-item {
      background: #464646;
      padding: 3px 10px;

      &.sv-radio--allowhover,
      &.sv-radio--checked {
        width: 112px;
      }

      &:nth-child(1) {
        border-bottom-left-radius: 2px;
        border-top-left-radius: 2px;
      }

      &:nth-last-child(1) {
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;
      }

      &.sv-radio--checked {
        background: #656565;
        border-radius: 2px;

        .sv-radio__svg {
          border-color: #fff;
          fill: #fff;
        }

        .ampersand-ai-label {
          color: #fff;
        }
      }

      .ampersand-ai-label {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0.4px;
        color: #f4f4f4;
        cursor: pointer;

        .sv-item__control-label {
          margin-left: 5px;
        }

        .sv-radio__svg {
          border-color: #ddd0f2;
          width: 20px;
          height: 20px;
        }

        .sv-item__decorator {
          width: 29px;
          height: 29px;
        }

        .sv-item__control:focus + .sv-item__decorator {
          border: none;
        }
      }
    }
  }
`;

const StyledBody = styled.div`
  flex: 1;
  overflow: auto;
  padding: 54px 0 0 0;
`;

const StyledFooter = styled.footer`
  padding: 15px calc(10% + 0.55em);
  position: sticky;
  bottom: 0;
  border-top: 1px solid #000;
  z-index: 10;
  background-color: #202020;

  p:nth-child(1) {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.4px;
    color: #ececec;
    margin: 0 0 2px 0;
  }

  button:nth-child(2) {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #f8ac2f;
  }
`;

const InformationButton = styled.button``;
