import React from "react";
import styled from "styled-components";

export interface OutputPreviewProps {
  image: any;
  text: string;
}

export const OutputPreview: React.FC<OutputPreviewProps> = (props) => {
  return (
    <StyledOutputPreview>
      <img src={props.image} alt="" />
      <Content>
        <p>{props.text}</p>
      </Content>
    </StyledOutputPreview>
  );
};

const StyledOutputPreview = styled.div`
  max-width: 758px;
  width: 100%;
  height: 108px;
  background: #4b4c54;
  display: flex;
  overflow: hidden;
`;

const Content = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.4px;
  color: #ececec;
  padding: 18px;
  overflow: auto;

  p {
    max-width: 171px;
    width: 100%;
  }
`;
