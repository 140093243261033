import React from "react";
import styled from "styled-components";
import CheckMarkIcon from "./CheckmarkIcon";

export interface TeamGoalsProps {
  assets: number;
  done: number;
}

export const TeamGoals: React.FC<TeamGoalsProps> = ({
  assets = 0,
  done = 0,
  ...props
}) => {
  return (
    <StyledTeamGoals>
      <AssetsText>{assets} Assets</AssetsText>
      <DoneText>
        <CheckMarkIcon width={32} height={22} />
        {done} Done
      </DoneText>
    </StyledTeamGoals>
  );
};

const StyledTeamGoals = styled.div``;

const AssetsText = styled.span`
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.4px;
  color: #ececec;
`;

const DoneText = styled.span`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #ffffff;
`;
