import React from "react";
import styled from "styled-components";
import { ErrorComponent } from "../components/ErrorComponent";
import { useNavigate } from "react-router-dom";

export interface AccessDeniedProps {
  errorTitle: string;
  errorDescription: string;
  handleBackNavigation?: () => void;
}

export const AccessDenied: React.FC<AccessDeniedProps> = (props) => {
  const navigate = useNavigate();

  function handleBackNavigation() {
    if (props.handleBackNavigation) {
      props.handleBackNavigation();
    } else {
      navigate("/login", { replace: true });
    }
  }

  return (
    <StyledAccessDenied className="access-denied">
      <ErrorComponent
        title={props.errorTitle}
        description={props.errorDescription}
        handleBackNavigation={() => handleBackNavigation()}
        buttonText="Back to login"
      />
    </StyledAccessDenied>
  );
};

const StyledAccessDenied = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
