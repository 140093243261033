import React from "react";
import { Navigate, Route } from "react-router-dom";
import { isAuthenticated } from "../utils";

export const UnAuthenticatedRoute = (props: {
  path: string;
  element: any;
  children?: React.ReactNode;
}) => {
  const signedIn = isAuthenticated();

  if (signedIn) {
    return <Navigate to="/overview" />;
  }

  return (
    <Route path={props.path} element={React.cloneElement(props.element)}>
      {props.children}
    </Route>
  );
};
