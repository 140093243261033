import React from "react";
import { AccessDenied } from "../pages/AccessDenied";
import { Auth0Context } from "../Auth0Context";
import { ERROR_CODES } from "../constants";

export interface NoApplicationsFoundProps {}

export const NoApplicationsFound: React.FC<NoApplicationsFoundProps> = (
  props
) => {
  const Auth0 = React.useContext(Auth0Context);

  return (
    <AccessDenied
      errorTitle={ERROR_CODES.NO_APPLICATIONS.TITLE}
      errorDescription={ERROR_CODES.NO_APPLICATIONS.DESCRIPTION}
      handleBackNavigation={() => Auth0.logout()}
    />
  );
};
