import React from "react";
import styled from "styled-components";
import isNil from "lodash.isnil";
import { Progress } from "../components/Progress";
import { Instructions } from "../components/Instructions";
import { SurveyJS } from "../components/SurveyJS";
import { useRecoilCallback, useRecoilState } from "recoil";
import { FeedbackState } from "../state";
import {
  useCreateResponse,
  useFetchApplicationsForUser,
  useFetchFormForApplication,
  useFetchNextTestCaseForApplicationForUser,
  useFetchResponsesForUserForApplication,
  useFetchTestCasesForApplication,
  useUpdateApplicationForUser,
  useUpdateResponse,
} from "../hooks";
import { getUserId } from "../utils";
import { TimerContext } from "../TimerContext";
// import { ImageViewer } from "../components/ImageViewer";
import { TailSpin } from "react-loading-icons";
import { NoApplicationsFound } from "../components/NoApplicationsFound";
import { Navigate } from "react-router-dom";

export interface FeedbackProps {}

interface ImageViewersContainerProps {
  showInstructions?: boolean;
}

export const Feedback: React.FC<FeedbackProps> = (props) => {
  const updateApplicationForUserMutation = useUpdateApplicationForUser();

  const { TimeMe } = React.useContext(TimerContext);

  const userId = getUserId();

  const [showInstructions, setShowInstructions] = React.useState<
    boolean | null
  >(null);

  const {
    data: userApplications,
    isLoading: isLoadingUserApplications,
  } = useFetchApplicationsForUser(getUserId());

  const userApplication =
    userApplications && userApplications.length > 0
      ? userApplications[0]
      : null;

  const userApplicationId = userApplication ? userApplication.id : null;

  const applicationId =
    userApplications && userApplications.length > 0
      ? userApplications[0].application.id
      : "";

  const {
    data: testCases,
    isLoading: isLoadingTestCases,
  } = useFetchTestCasesForApplication(applicationId);

  const { data: form, isLoading: isLoadingForm } = useFetchFormForApplication(
    applicationId
  );

  const {
    data: responses,
    isLoading: isLoadingResponses,
  } = useFetchResponsesForUserForApplication({
    userId,
    applicationId,
  });

  const {
    data: nextTestCase,
    isLoading: isLoadingNextTestCase,
  } = useFetchNextTestCaseForApplicationForUser({
    userId,
    applicationId,
  });

  const createResponse = useCreateResponse(applicationId as number);

  const updateResponse = useUpdateResponse(applicationId as number);

  const [showCompleted, setShowCompleted] = React.useState<null | boolean>(
    null
  );

  const [feedbackState, setFeedbackState] = useRecoilState(FeedbackState);

  const getFeedbackState = useRecoilCallback(({ snapshot }) => async () => {
    const feedbackState = await snapshot.getPromise(FeedbackState);
    return feedbackState;
  });

  const [timerId, setTimerId] = React.useState<null | any>(null);

  // eslint-disable-next-line
  const images = feedbackState.previousResponse
    ? feedbackState.previousResponse.images
    : nextTestCase
    ? nextTestCase.images
    : [];

  React.useEffect(() => {
    const { previousResponse } = feedbackState;

    if (previousResponse && previousResponse.testCase.id !== timerId) {
      const timerId = previousResponse.testCase.id;
      // console.log("Starting new timer");
      TimeMe.startTimer(timerId);
      setTimerId(timerId);
    }

    if (!previousResponse && nextTestCase && nextTestCase.id !== timerId) {
      // console.log("Starting new timer");
      const timerId = nextTestCase.id;
      TimeMe.startTimer(timerId);
      setTimerId(timerId);
    }
  }, [nextTestCase, feedbackState.previousResponse, timerId]); // eslint-disable-line

  React.useEffect(() => {
    if (percentageComplete() === 100) {
      setShowCompleted(true);
    } else {
      setShowCompleted(false);
    }
  }, [responses?.length, testCases?.length]); // eslint-disable-line

  React.useEffect(() => {
    // initialize whether or not to show application instructions
    if (userApplication && isNil(showInstructions)) {
      setShowInstructions(!userApplication.hasStarted);
    }
  }, [userApplication, showInstructions]);

  async function onCompleted(json: any) {
    const { previousResponse } = await getFeedbackState();

    // stop the timer
    TimeMe.stopTimer(timerId);
    // if there is a previous resp set, we can assume we are updating an existing resp
    if (!isNil(previousResponse)) {
      updateResponse.mutate({
        response: json,
        id: previousResponse.id,
        timeSpent: Math.round(TimeMe.getTimeOnPageInSeconds(timerId)), // in seconds
        index: previousResponse.index,
      });
    } else {
      // create a new response for the test case
      createResponse.mutate({
        response: json,
        formId: Number(form?.id),
        applicationId: Number(applicationId),
        userId: Number(userId),
        testCaseId: Number(nextTestCase?.id),
        timeSpent: Math.round(TimeMe.getTimeOnPageInSeconds(timerId)), // in seconds
      });
    }
  }

  function onStart() {
    setShowInstructions(false);

    if (userApplicationId && !userApplication?.hasStarted) {
      updateApplicationForUserMutation.mutate({
        payload: { hasStarted: true },
        userApplicationId,
      });
    }
  }

  function percentageComplete() {
    if (responses && testCases) {
      return (responses.length / testCases.length) * 100;
    } else {
      return 0;
    }
  }

  function handleOnBack() {
    const { previousResponse } = feedbackState;

    if (responses && responses.length > 0) {
      let index: number;

      if (isNil(previousResponse)) {
        index = responses.length - 1; // get the last response
      } else if (previousResponse.index !== 0) {
        index = previousResponse.index - 1; // keep going back
      } else {
        index = previousResponse.index; // return first element as default condition
      }

      // set the previous response
      setFeedbackState({
        previousResponse: { ...responses[index], index },
      });
    }
  }

  function handleInformationButtonClick() {
    setShowInstructions(true);
  }

  if (showCompleted) {
    return <Navigate to="complete" />;
  }

  if (
    isNil(showCompleted) ||
    isLoadingForm ||
    isLoadingUserApplications ||
    isLoadingTestCases ||
    isLoadingResponses ||
    isLoadingNextTestCase
  ) {
    return (
      <LoadingContainer>
        <TailSpin height="5em" width="5em" fill="#a0a0a0" stroke="#a0a0a0" />
      </LoadingContainer>
    );
  }

  if (userApplications && userApplications.length === 0) {
    return <NoApplicationsFound />;
  }

  return (
    <StyledFeedback className="feedback">
      <Header className="feedback__header">
        <ProgressContainer>
          <Text>Your Progress</Text>
          <Progress percentage={percentageComplete()} />{" "}
          <ProgressText>
            {responses?.length} / {testCases?.length}
          </ProgressText>
        </ProgressContainer>
      </Header>

      <Content className="feedback__content">
        <SidePanel className="feedback__content_side-panel">
          {!isNil(showInstructions) && showInstructions && (
            <Instructions onStart={onStart} />
          )}

          {!isNil(showInstructions) && !showInstructions && (
            <SurveyJS
              title={nextTestCase?.name as string}
              json={{ elements: form?.content }}
              onComplete={onCompleted}
              onBack={handleOnBack}
              onSkip={() => {}}
              onInformationButtonClick={handleInformationButtonClick}
            />
          )}
        </SidePanel>

        <ImageViewersContainer
          className="feedback__image-viewer-container"
          showInstructions={!!showInstructions}
        >
          {/*<ImageViewer
            title="Image A"
            image={images && images.length > 1 ? images[0] : undefined}
          />

          <VerticalDivider />

          <ImageViewer
            title="Image B"
            image={images && images.length > 1 ? images[1] : undefined}
          />*/}

          <iframe
            width="1000"
            height="500"
            frameBorder="0"
            title="dicom-viewer"
            className="dicom-iframe"
            src={nextTestCase?.dataUrl ?? ""}
            allowFullScreen
          />
        </ImageViewersContainer>
      </Content>
    </StyledFeedback>
  );
};

const StyledFeedback = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  height: 100%;
  flex: 1;
`;

const Header = styled.div`
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 50px;
`;

const Content = styled.div`
  display: flex;
  height: 100%;
  min-height: 0;
  flex: 1;
  margin: 0 50px 50px 50px;
  background: rgba(32, 32, 32, 0.42);
`;

const SidePanel = styled.div`
  width: 400px;
  background: rgba(32, 32, 32, 1);
  min-height: 0;
  height: 100%;
  overflow: auto;
  flex-shrink: 0;
`;

const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.4px;
  color: rgba(255, 255, 255, 0.72);
  margin: 0 41px 0 0;
`;

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 8px 0;
`;

const ProgressText = styled.span`
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: rgba(255, 255, 255, 0.51);
  margin: 0 0 0 9px;
`;

const ImageViewersContainer = styled.div<ImageViewersContainerProps>`
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
  opacity: ${(props) =>
    props.showInstructions || isNil(props.showInstructions) ? 0.1 : 1};
  pointer-events: ${(props) =>
    props.showInstructions || isNil(props.showInstructions)
      ? "none"
      : "initial"};
  transition: opacity 0.2s ease-in-out;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    background-color: #333;
  }
`;

// const VerticalDivider = styled.div`
//   background: #000000;
//   width: 1px;
//   height: 100%;
// `;

const LoadingContainer = styled.div`
  height: calc(100% - 65px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
