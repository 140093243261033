import * as React from "react";

export function BackArrowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 10 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 1.998L3.82 8.5 10 15.002 8.097 17 0 8.5 8.097 0 10 1.998z"
        fill="#DDD0F2"
        fillOpacity={0.3}
      />
    </svg>
  );
}
