import React, { createContext } from "react";
import { useIdleTimer } from "react-idle-timer";
import { Auth0Context } from "./Auth0Context";
import { APPLICATION_TIMEOUT, AUTH_STATE, AUTH_STATE_KEY } from "./constants";

type IdleTimeoutContextType = {};

export const IdleTimeoutContext = createContext({} as IdleTimeoutContextType);

export const IdleTimeoutProvider: React.FC = (props) => {
  const Auth0 = React.useContext(Auth0Context);

  const handleOnIdle = (event: any) => {
    const authState = localStorage.getItem(AUTH_STATE_KEY);
    const isLoggedIn = authState === AUTH_STATE.SIGNED_IN;

    if (isLoggedIn) {
      Auth0.logout("/session-timeout");
    }

    // console.log("user is idle", event);
    // console.log("last active", getLastActiveTime());
  };

  const handleOnActive = (event: any) => {
    // console.log("user is active", event);
    // console.log("time remaining", getRemainingTime());
  };

  const handleOnAction = (event: any) => {
    // console.log("user did something", event);
  };

  // eslint-disable-next-line
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: APPLICATION_TIMEOUT, // 15 minutes
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  return (
    <IdleTimeoutContext.Provider value={{}}>
      {props.children}
    </IdleTimeoutContext.Provider>
  );
};
