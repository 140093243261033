import React from "react";
import styled from "styled-components";
import { ErrorComponent } from "../components/ErrorComponent";
import { useNavigate } from "react-router-dom";

export interface SessionTimeoutProps {}

export const SessionTimeout: React.FC<SessionTimeoutProps> = (props) => {
  const navigate = useNavigate();

  return (
    <StyledSessionTimeout className="session-timeout">
      <ErrorComponent
        title="Your session has timed out"
        description="For security reasons your session times out after you've been inactive for a while."
        handleBackNavigation={() => navigate("/login", { replace: true })}
        buttonText="Back to login"
      />
    </StyledSessionTimeout>
  );
};

const StyledSessionTimeout = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
