import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

export interface MenuProps {}

export const Menu: React.FC<MenuProps> = ({ children, ...props }) => {
  return <StyledMenu {...props}>{children}</StyledMenu>;
};

const StyledMenu = styled(motion.div)`
  width: 208px;
  height: 85px;
  background: #55565d;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
`;
