import React, { createContext } from "react";
// @ts-ignore
import * as TimeMe from "timeme.js";

type TimerContextType = {
  TimeMe: any;
};

export const TimerContext = createContext({} as TimerContextType);

export const TimerProvider: React.FC = (props) => {
  React.useEffect(() => {
    console.log("Application timer started");
    TimeMe.startTimer("app");
  }, []);

  return (
    <TimerContext.Provider value={{ TimeMe }}>
      {props.children}
    </TimerContext.Provider>
  );
};
