import React from "react";
import styled from "styled-components";

export interface ProgressProps {
  percentage: number;
}

export const Progress: React.FC<ProgressProps> = ({
  percentage = 0,
  ...props
}) => {
  return <StyledProgress percentage={percentage} />;
};

const StyledProgress = styled.div<ProgressProps>`
  width: 69px;
  height: 6px;
  background: rgba(72, 73, 81, 1);
  border-radius: 13px;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    background: rgba(119, 216, 84, 1);
    border-radius: 13px;
    transition: width 0.2s ease-in-out;
    width: ${(props) => props.percentage}%;
    height: 100%;
  }
`;
