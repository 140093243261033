import React from "react";
import styled from "styled-components";
import { Logo } from "./Logo";
import { Button } from "./Button";

export interface ErrorComponentProps {
  title: string;
  description: string;
  handleBackNavigation: () => void;
  buttonText?: string;
}

export const ErrorComponent: React.FC<ErrorComponentProps> = ({
  title,
  description,
  handleBackNavigation,
  children,
  ...props
}) => {
  return (
    <StyledErrorComponent {...props} className="error-component">
      <div>
        <div className="error-component__logo-container">
          <Logo width="90px" />
        </div>

        <div className="error-component__content-container">
          <header>
            <h2>{title}</h2>
          </header>

          <StyledDescription>{description}</StyledDescription>

          <StyledButton
            primary
            label={props.buttonText || "Back to home"}
            onClick={handleBackNavigation}
          />

          {children}
        </div>
      </div>
    </StyledErrorComponent>
  );
};

const StyledErrorComponent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    display: flex;
    height: min-content;
  }

  h2 {
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 0.4px;
    color: #ffffff;
    max-width: 546px;
    text-align: left;
  }

  header {
    padding: 25px 0 0 0;
    display: flex;
  }

  .error-component__content-container {
    margin: 0 0 0 22px;
  }
`;

const StyledButton = styled(Button)`
  width: 165px;
  margin: 0 0 40px 0;
`;

const StyledDescription = styled.p`
  margin: 20px 0 32px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.4px;
  color: #ececec;
  max-width: 446px;
  text-align: left;
`;
