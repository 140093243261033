import "destyle.css/destyle.css";
import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { Auth0Provider } from "./Auth0Context";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { IdleTimeoutProvider } from "./IdleTimeoutContext";
import { TimerProvider } from "./TimerContext";

// Create a query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const isDevelopment = process.env.NODE_ENV === "development";

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <RecoilRoot>
          <TimerProvider>
            <Auth0Provider>
              <IdleTimeoutProvider>
                <App />
              </IdleTimeoutProvider>
              {isDevelopment && <ReactQueryDevtools initialIsOpen={false} />}
            </Auth0Provider>
          </TimerProvider>
        </RecoilRoot>
      </Router>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
