import React from "react";
import styled from "styled-components";
import { Input } from "./Input";
import { Button } from "./Button";
import { GoogleSignInButton } from "./GoogleSignInButton";
import { Auth0Context } from "../Auth0Context";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "./ErrorMessage";
import { Link } from "react-router-dom";

type Inputs = {
  email: string;
  password: string;
};

export const Login: React.FC = () => {
  const [error, setError] = React.useState<string | null | undefined>(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isDirty }, // eslint-disable-line
  } = useForm<Inputs>();
  const Auth0 = React.useContext(Auth0Context);

  function handleGoogleSignIn() {
    Auth0.webAuth.authorize({
      connection: "google-oauth2",
    });
  }

  function onSubmit(data: Inputs) {
    Auth0.webAuth.login(
      {
        ...data,
        realm: "Username-Password-Authentication",
      },
      (err, authResult) => {
        if (err) {
          setError(err.description);
          reset();
        }
      }
    );
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormTitle>Log In</FormTitle>

        {error && !isDirty && (
          <ErrorMessageContainer>
            <ErrorMessage message="Sorry! The email or password entered isn’t right. We can help you recover it if needed." />
          </ErrorMessageContainer>
        )}

        <FormElementsWrapper>
          <FormControl>
            <Label>Email</Label>
            <Input type="email" {...register("email", { required: true })} />
          </FormControl>

          <FormControl>
            <Label>Password</Label>
            <Input
              type="password"
              {...register("password", { required: true })}
            />
          </FormControl>

          <ForgotPasswordText to="/change-password">
            Forgot Your Password?
          </ForgotPasswordText>

          <StyledButton
            // @ts-ignore
            type="submit"
            label="Login"
            disabled={isSubmitting}
          />
        </FormElementsWrapper>
      </Form>

      <StyledText>Or</StyledText>

      {/*@ts-ignore*/}
      <StyledGoogleSignInButton onClick={handleGoogleSignIn} />

      <Footer>
        <p>First Time User?</p>
        <Link to="/sign-up">Create a new account</Link>
      </Footer>
    </div>
  );
};

const Text = styled.p`
  max-width: 468px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.4px;
  color: #ececec;
  margin: 16px 0 0 0;
`;

const Form = styled.form`
  margin: 32px 0 0 0;
  width: 100%;
  flex-shrink: 0;
`;

const FormTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 1.4px;
  color: #ececec;
  margin: 0 0 9px 0;
  text-transform: uppercase;
  flex-shrink: 0;
`;

const FormControl = styled.div`
  margin: 0 0 15px 0;
  flex-shrink: 0;
`;

const Label = styled.label`
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #c4c4c4;
  margin: 0 0 9px 0;
  flex-shrink: 0;
`;

const StyledButton = styled(Button)`
  margin: 25px 0 0 0;
  flex-shrink: 0;

  :disabled {
    cursor: not-allowed;
  }
`;

const StyledText = styled(Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #c4c4c4;
  margin: 25px 0;
  text-align: center;
  max-width: 256px;
  width: 100%;
  flex-shrink: 0;
`;

const StyledGoogleSignInButton = styled(GoogleSignInButton)`
  max-width: 256px;
  flex-shrink: 0;
`;

const Footer = styled.footer`
  margin: 65px 0 0 0;
  flex-shrink: 0;

  p {
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 23px;
    letter-spacing: 1.4px;
    color: #ececec;
    flex-shrink: 0;
  }

  a {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #c4c4c4;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;

    &:after {
      content: "";
      display: block;
      height: 1px;
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 125px;
      border-bottom: 1px solid #c4c4c4;
    }
  }
`;

const ErrorMessageContainer = styled.div`
  margin: 12px 0;
  max-width: 411px;
`;

const FormElementsWrapper = styled.div`
  max-width: 256px;
`;

const ForgotPasswordText = styled(Link)`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 1.4px;
  color: #ececec;
  margin: 0 0 9px 0;
  text-transform: uppercase;
  flex-shrink: 0;
  text-align: right;
  display: inline-block;
  width: 100%;
`;
