import * as React from "react";

function CheckMarkIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.25 14.822L4.428 11l-1.302 1.292 5.124 5.124 11-11-1.292-1.292-9.708 9.698z"
        fill="#C4C4C4"
      />
    </svg>
  );
}

export default CheckMarkIcon;
